/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { ProductComponentProps, ProductContainerProps } from 'BaseComponent/Product/Product.type';
import { ProductContainer } from 'SourceComponent/Product/Product.container';
import { calculateProductIncrements } from 'Util/ProductQuantities';

// Override for the CartItem container, adding isQtyMoreThanStock to the
// component properties.

const containerProps = (
    args: [],
    callback: () => ProductComponentProps,
    instance: ProductContainer<ProductContainerProps>,
): ProductComponentProps => {
    const { quantity: tempQty } = instance.state;
    const stateQuantity = Array.isArray(tempQty)
        ? tempQty.pop()
        : tempQty;
    const { product } = instance.props;
    const { minimum } = calculateProductIncrements(product);
    const defaultQuantity = minimum ?? 0;

    if (stateQuantity < defaultQuantity) {
        instance.setQuantity(defaultQuantity);
    }

    return callback.apply(instance, args);
};

export default {
    'Component/Product/Container': {
        'member-function': {
            containerProps,
        },
    },
};

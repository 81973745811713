/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { ProductPriceComponentProps } from 'Component/ProductPrice/ProductPrice.type';
import { ProductPriceContainer } from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    ProductPriceComponentProps as SourceProductPriceComponentProps,
} from 'SourceComponent/ProductPrice/ProductPrice.type';

const containerProps = (
    args: [],
    callback: () => SourceProductPriceComponentProps,
    instance: ProductPriceContainer,
): ProductPriceComponentProps => {
    // @ts-ignore
    const {
        prefixLabel = '',
        suffixLabel = '',
    }: ProductPriceComponentProps = instance.props;

    return {
        ...callback.apply(instance, args),
        prefixLabel,
        suffixLabel,
    };
};

const defaultProps = (
    originalProps: Partial<SourceProductPriceComponentProps>,
): Partial<ProductPriceComponentProps> => ({
    ...originalProps,
    prefixLabel: '',
    suffixLabel: '',
});

export default {
    'Component/ProductPrice/Container': {
        'member-function': {
            containerProps,
        },
        'static-member': {
            defaultProps,
        },
    },
};

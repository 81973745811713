// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode} from 'react';

import {
    RENDER_ADDITIONAL_CARD_ACTIONS,
    RENDER_CARD_COMPARE,
    RENDER_CARD_REVIEWS,
    RENDER_CARD_WISHLIST,
    RENDER_DETAILS_STOCK_INFO,
} from 'Component/ProductCard/ProductCard.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {GQLProductStockStatus} from 'Type/Graphql.type';
import {getVintageLabel} from 'Util/Vintage';

import './ProductCard.override-base.style';

/** @namespace Lebonvin/PwaBase/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    // Override. Optionally removes reviews based on config.
    renderReviews(): ReactNode {
        if (RENDER_CARD_REVIEWS) {
            return super.renderReviews();
        }

        return null;
    }

    // Override. Modifies the card content for the block layout, by adding the
    // main details section to the card as well, byt also removing the explicit
    // name element, since the main details has this.
    renderCardContent(): ReactNode {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { RENDER_CARD_REVIEWS && this.renderReviews() }
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }

    // Override. Prevents rendering the product actions wrapper if there is no
    // content to display, even if the theme is configured to allow elements.
    renderProductActions(): ReactNode {
        const {hideCompareButton, hideWishlistButton, isWishlistEnabled} = this.props;
        const compareVisible = RENDER_CARD_COMPARE && !hideCompareButton;
        const wishlistVisible = RENDER_CARD_WISHLIST && isWishlistEnabled && !hideWishlistButton;

        if (!compareVisible && !wishlistVisible && !RENDER_ADDITIONAL_CARD_ACTIONS) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                { RENDER_CARD_WISHLIST && this.renderProductCardWishlistButton() }
                { RENDER_CARD_COMPARE && this.renderProductCompareButton() }
                { RENDER_ADDITIONAL_CARD_ACTIONS && this.renderAdditionalActionsButtons() }
            </div>
        );
    }

    // ADDITION. Specifically for override by other themes to optionally include
    // additional buttons in the hover action. Will also required override on
    // the configuration before this will render.
    renderAdditionalActionsButtons(): ReactNode {
        return null;
    }

    // Override. By default, this only display in list view but other overrides
    // might use this in additional views. Also adding additional information.
    renderMainDetails(): ReactNode {
        const {
            product: {
                name,
                attributes: {
                    vintage = null,
                } = {},
            },
        } = this.props;

        // Slight hack, but making this undefined allows the text placeholder to
        // work as intended by not rendering this until we have data. Null
        // vintage (i.e. no vintage data for the product) should correctly show
        // as 'No Vintage'.
        const vintageLabel = name !== undefined ? getVintageLabel(vintage) : undefined;

        return (
            <div block="ProductCard" elem="Details">
                <p
                  block="ProductCard"
                  elem="Name"
                  mods={ { isLoaded: !!name } }
                >
                    <TextPlaceholder content={ name } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
                <div block="ProductCard" elem="ContentDetails">
                    <TextPlaceholder content={ vintageLabel } length={ TextPlaceHolderLength.SHORT } />
                </div>
                { RENDER_DETAILS_STOCK_INFO && this.renderMainDetailsStockInfo() }
                { this.renderMainDetailsAdditionalInfo() }
            </div>
        );
    }

    // ADDITION. Will render directly, but also allows other themes to override
    // the specific details rendered.
    renderMainDetailsStockInfo(): ReactNode {
        const {
            product: {
                stock_status,
            },
        } = this.props;
        const stockDefault: string = stock_status === GQLProductStockStatus.IN_STOCK
            ? 'In Stock'
            : 'Out of Stock';
        const stockMessage: string | undefined = stock_status !== undefined
            ? stockDefault
            : undefined;

        return (
            <div block="ProductCard" elem="ContentDetails">
                <TextPlaceholder
                  content={ stockMessage }
                  length={ TextPlaceHolderLength.SHORT }
                />
            </div>
        );
    }

    // ADDITION. Specifically for override by other themes to optionally include
    // additional content in the main details section.
    renderMainDetailsAdditionalInfo(): ReactNode {
        return null;
    }
}

export default ProductCardComponent;

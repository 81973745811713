// noinspection JSValidateJSDoc

import {IndexedAttributeWithValue, IndexedAttributeWithValueOption} from 'Util/Product/Product.type';

export const NO_VINTAGE = 'No vintage';

/** @namespace Lebonvin/PwaBase/Util/Vintage/Label/getVintageLabel */
export function getVintageLabel(vintageAttribute: IndexedAttributeWithValue | null): string {
    if (!vintageAttribute) {
        return NO_VINTAGE;
    }

    const value = vintageAttribute.attribute_value;
    const option: IndexedAttributeWithValueOption = vintageAttribute.attribute_options[value];

    if (!option) {
        return NO_VINTAGE;
    }

    return option.label
        .replace('NV', NO_VINTAGE)
        .replace('Unknown', NO_VINTAGE);
}

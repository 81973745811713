// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode} from 'react';

import {
    ProductType,
    RENDER_PRODUCT_COMPARE,
    RENDER_PRODUCT_REVIEWS,
    RENDER_PRODUCT_TIER_PRICES,
} from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import {
    ProductComponent as SourceProductComponent,
} from 'SourceComponent/Product/Product.component';
import { ProductComponentProps as SourceProductComponentProps } from 'SourceComponent/Product/Product.type';

import './Product.override.style';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

/** @namespace Lebonvin/PwaBase/Component/Product/Component */
export class ProductComponent<
    P extends SourceProductComponentProps = SourceProductComponentProps,
> extends SourceProductComponent<P> {
    // Override. Optionally removes the compare button.
    renderCompareButton(): ReactNode {
        if (RENDER_PRODUCT_COMPARE) {
            return super.renderCompareButton();
        }

        return null;
    }

    // Override. Optionally removes product reviews.
    renderRatingSummary(): ReactNode {
        if (RENDER_PRODUCT_REVIEWS) {
            return super.renderRatingSummary();
        }

        return null;
    }

    // Override. Removes default stock element, for safety since we render stock
    // info in other ways / places.
    renderStock(): ReactNode {
        return null;
    }

    // Override. Optionally removing the tier prices based on the configuration.
    renderPrice(isPreview = false): ReactNode {
        if (RENDER_PRODUCT_TIER_PRICES) {
            return super.renderPrice(isPreview);
        }

        const { getActiveProduct, productPrice } = this.props;
        const { type_id } = getActiveProduct();

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ productPrice }
                  priceType={ type_id as ProductType }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }
}

export default ProductComponent;

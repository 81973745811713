// noinspection JSValidateJSDoc

import {SecureIndexedCartItem} from 'Store/Cart/Cart.secure.type';

/** @namespace Lebonvin/PwaSecure/Util/ProductQuantities/CartItem/getCartItemQtyMoreThanStock */
export function getCartItemQtyMoreThanStock(item: SecureIndexedCartItem | Partial<SecureIndexedCartItem>): boolean {
    // Guard to ensure we have data - if this is missing from a cart item, we
    // have not got the full data yet.
    if (!item?.quantity) {
        return false;
    }

    return item.quantity > (item.product?.stock_item?.qty || 0);
}

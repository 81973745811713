// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {
    AddToCartComponent as SourceAddToCartComponent,
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style.scss';

/** @namespace Lebonvin/PwaBase/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCartComponent {}

export default AddToCartComponent;

/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { Dispatch } from 'redux';

// import CartQuery from 'Query/Cart.query';
import { CartDispatcher } from 'Store/Cart/Cart.dispatcher';
// import { fetchQuery } from 'Util/Request/Query';

// Override for the CartDispatcher, since we can't override an async function
// via extension (triggers a babel error, but the babel config does not appear
// to be directly available via CRACO overrides.)

const updateInitialCartData = async (
    args: [Dispatch, boolean | undefined, boolean | undefined],
    callback: (
        dispatch: Dispatch,
        isForCustomer: boolean | undefined,
        disableLoader: boolean | undefined,
    ) => Promise<string | null>,
    instance: CartDispatcher,
): Promise<string | null> => {
    // const dispatch = args[0];
    // const quoteId = await instance._getCartId(dispatch);

    // try {
    //     await fetchQuery(
    //         CartQuery.getCartQuery(quoteId || ''),
    //     );
    // } catch (e) {
    //     // eslint-disable-next-line no-console
    //     console.log('And error here completely suppresses the data');
    // }

    return callback.apply(instance, args);
};

// const mergeCarts = async (
//     args: [string, string, Dispatch],
//     callback: (
//         sourceCartId: string,
//         destinationCartId: string,
//         dispatch: Dispatch,
//     ) => Promise<string | null>,
//     instance: unknown,
// ): Promise<string | null> => {
//     return callback.apply(instance, args);
// };

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData,
            // mergeCarts,
        },
        'member-property': {},
    },
};

// noinspection JSValidateJSDoc

import {SecureSimpleProduct} from 'Query/ProductList.secure.type';
import {SecureIndexedProduct} from 'Util/Product/Product.secure.type';

export interface ProductQuantities {
    step: number;
    minimum: number;
    maximum: number;
}

/** @namespace Lebonvin/PwaSecure/Util/ProductQuantities/Increments/calculateProductIncrements */
export function calculateProductIncrements(
    product: SecureIndexedProduct | Partial<SecureSimpleProduct>,
    minQuantity?: number,
    maxQuantity?: number,
): ProductQuantities {
    const {
        multiple,
        salable_qty = 0,
        stock_item: {
            qty,
            qty_increments,
            min_sale_qty,
            max_sale_qty,
        } = {},
    } = product;

    // Guard against undefined values and resulting potential for NaNs (more
    // efficient guards aren't recognised by the compiler, hence the long form.)
    if (qty === undefined
        || qty_increments === undefined
        || min_sale_qty === undefined
        || max_sale_qty === undefined
    ) {
        return { step: 0, minimum: -1, maximum: -1 };
    }

    // Allow override of min and max from parameters, because they account for
    // other factors, but if not we account for saleable quantity (if present.)
    const min = minQuantity ?? min_sale_qty;
    const max = maxQuantity ?? (max_sale_qty <= salable_qty ? max_sale_qty : salable_qty);

    // We are manually converting multiple to a string to be safe, because this is the original code.
    const step = (multiple ?? 0) > qty_increments ? parseInt(`${multiple}`, 10) : qty_increments;
    const minimum = Math.floor(min / step) * step + (min % step === 0 ? 0 : step);
    const ceiling = qty < max ? qty : max;
    const maximum = ceiling - (ceiling % step);

    return {
        step,
        minimum,
        maximum,
    };
}

// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {
    CartDispatcher as SourceCartDispatcher,
} from 'SourceStore/Cart/Cart.dispatcher';

export {
    CURRENT_WEBSITE,
} from 'SourceStore/Cart/Cart.dispatcher';

/** @namespace Lebonvin/PwaBase/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {}

export default new CartDispatcher();

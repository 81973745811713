// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode} from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {
    ProductCardComponent as SecureProductCardComponent,
} from 'SecureComponent/ProductCard/ProductCard.component';

import './ProductCard.override-witradis.style';

/** @namespace Lebonvin/PwaWitradis/Component/ProductCard/Component */
export class ProductCardComponent extends SecureProductCardComponent {
    // Override. Rendering not just specific stock amounts but also extended
    // stock availability and origin.
    renderMainDetailsStockInfo(): ReactNode {
        // Ignoring multiple type errors about missing attributes, because they
        // are added to the product query, but there's no easy way to override
        // the props definition of the class.
        const {
            product: {
                // @ts-ignore
                automation_source,
                salable_qty,
                // @ts-ignore
                stock_bond,
                // @ts-ignore
                stock_combined,
                stock_item: {
                    // @ts-ignore
                    qty = undefined,
                } = {},
            },
        } = this.props;

        // Because using `let` is frowned on enough that it needs to be
        // suppressed multiple times for each definition, use const object.
        const messageData: {[x: string]: string | undefined} = {
            qty: undefined,
            source: undefined,
            sourceClass: undefined,
        };

        if (salable_qty !== undefined) {
            if (automation_source !== 'witradis' && stock_bond > 0 && qty < stock_combined) {
                messageData.qty = `${stock_combined} combined stock.`;
            } else if (salable_qty < qty) {
                messageData.qty = `${salable_qty} available.`;
            } else {
                messageData.qty = `${qty} in stock.`;
            }

            if (automation_source === 'witradis') {
                messageData.source = 'FR';
                messageData.sourceClass = 'french-stock';
            } else {
                messageData.source = stock_bond > 0 ? 'UK+' : 'UK';
                messageData.sourceClass = 'uk-stock';
            }
        }

        return (
            <div block="ProductCard" elem="ContentDetails">
                <TextPlaceholder content={ messageData.qty } length={ TextPlaceHolderLength.SHORT } />
                &nbsp;
                &nbsp;
                <span block={ messageData.sourceClass }>
                    <TextPlaceholder content={ messageData.source } length={ TextPlaceHolderLength.SHORT } />
                </span>
            </div>
        );
    }
}

export default ProductCardComponent;

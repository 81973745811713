export {
    CheckoutSteps,
    CheckoutUrlSteps,
    CheckoutStepUrl,
    CHECKOUT_URL_REGEX,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY,
    UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY,
} from 'SourceRoute/Checkout/Checkout.config';

// Re-export the discount code rendering for consistency (configure in a single
// place) and simplicity.
export {
    RENDER_DISCOUNT_CODE,
} from 'Route/CartPage/CartPage.config';

// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode} from 'react';

import {
    ProductCardComponent as BaseProductCardComponent,
} from 'BaseComponent/ProductCard/ProductCard.component';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {prepareSoldInMessage} from 'Util/ProductQuantities';

/** @namespace Lebonvin/PwaSecure/Component/ProductCard/Component */
export class ProductCardComponent extends BaseProductCardComponent {
    renderMainDetailsAdditionalInfo(): ReactNode {
        const { product } = this.props;
        const soldIn = prepareSoldInMessage(product);

        return (
            <div block="ProductCard" elem="ContentDetails">
                <TextPlaceholder content={ soldIn } length={ TextPlaceHolderLength.SHORT } />
            </div>
        );
    }
}

export default ProductCardComponent;

// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode, Suspense} from 'react';

import {
    FieldContainer,
    ProductComponent as BaseProductComponent,
} from 'BaseComponent/Product/Product.component';
import { ProductComponentProps as SourceProductComponentProps } from 'BaseComponent/Product/Product.type';
import AddToCart from 'Component/AddToCart';
import { FieldType } from 'Component/Field/Field.config';
import {ProductType} from 'Component/Product/Product.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {calculateProductIncrements} from 'Util/ProductQuantities';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    AddToCart,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

/** @namespace Lebonvin/PwaSecure/Component/Product/Component */
export class ProductComponent<
    P extends SourceProductComponentProps = SourceProductComponentProps,
> extends BaseProductComponent<P> {
    // Override. Use increment calculations to modify the limiting factors of
    // the number control.
    renderQuantityChanger(): ReactNode {
        const {
            quantity,
            // minQuantity,
            // maxQuantity,
            setQuantity,
            inStock,
            product,
            product: { type_id },
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        // const { step, minimum: min, maximum: max } = calculateProductIncrements(product, minQuantity, maxQuantity);
        const { step, minimum: min, maximum: max } = calculateProductIncrements(product);
        const defaultValue = min > (quantity as number) ? min : quantity;

        return (
            <Suspense fallback={ null }>
                <FieldContainer
                  type={ FieldType.NUMBER_WITH_CONTROLS }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue,
                      max,
                      min,
                      step,
                  } }
                  validationRule={ {
                      inputType: ValidationInputTypeNumber.NUMERIC,
                      isRequired: true,
                      range: {
                          min,
                          max,
                      },
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: setQuantity } }
                  validateOn={ ['onChange'] }
                />
            </Suspense>
        );
    }

    // Override. Add calculations for the quantity to add to the cart.
    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactNode {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;
        const NUMBER_LESS_THAN_INCREMENT_ABORT_VALUE = -10;
        const product = getActiveProduct();
        const { salable_qty } = product;
        const { minimum } = calculateProductIncrements(product);
        const insufficientStock: boolean = minimum > (salable_qty ?? NUMBER_LESS_THAN_INCREMENT_ABORT_VALUE);
        const defaultQuantity = minimum ?? quantity;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock || insufficientStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ defaultQuantity }
                  product={ product }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;

// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactNode} from 'react';

import {ProductType} from 'Component/Product/Product.config';
import {BaseProductPriceComponentProps} from 'Component/ProductPrice/ProductPrice.type';
import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { GQLCurrencyEnum } from 'Type/Graphql.type';

import './ProductPrice.override.style';

/** @namespace Lebonvin/PwaBase/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    //
    static defaultProps = {
        ...SourceProductPriceComponent.defaultProps,
        priceCurrency: GQLCurrencyEnum.GBP,
        tierPrice: {
            currency: GQLCurrencyEnum.GBP,
            // Redefining these, because IDE reports type error if we try to
            // deconstruct the defaultProps for tier price.
            value: '',
            valueFormatted: '',
        },
        prefixLabel: '',
        suffixLabel: '',
    };

    render(): ReactNode {
        // @ts-ignore
        const {
            price: {
                finalPrice,
                originalPrice,
                // @ts-ignore
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
            prefixLabel,
            suffixLabel,
        }: BaseProductPriceComponentProps = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                <span block="Prefix">{ prefixLabel }</span>
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
                <span block="Suffix">{ suffixLabel }</span>
            </div>
        );
    }
}

export default ProductPriceComponent;

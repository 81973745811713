/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { SortDirections } from 'Route/CategoryPage/CategoryPage.config';
import { CategoryPageContainer } from 'Route/CategoryPage/CategoryPage.container';
import { CategorySortOptions } from 'Route/CategoryPage/CategoryPage.type';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

// Override for the category page to enforce that the default sort WHEN NOTHING
// ELSE IS SPECIFIED is set to position descending (default is position
// ascending, but the default sort direction is ALSO used as the default for ALL
// sort options; which means if price is the default sort, since magento does
// not specify it uses the default - and we need everything else to be ascending)

// const config = (
//     originalConfig: { [s: string]: unknown },
// ): { [s: string]: unknown } => ({
//     ...originalConfig,
//     sortKey: 'position',
//     sortDirection: SortDirections.DESC,
// });

const getSelectedSortFromUrl = (
    args: [],
    callback: () => CategorySortOptions,
    instance: CategoryPageContainer,
): CategorySortOptions => {
    const { location } = history;
    const paramKey = getQueryParam('sortKey', location);
    const paramDir = getQueryParam('sortDirection', location);
    const result = callback.apply(instance, args);

    if (!paramKey && !paramDir) {
        if (result.sortKey === 'position') {
            return {
                sortDirection: SortDirections.DESC,
                sortKey: 'position',
            };
        }
    }

    return result;
};

export default {
    'Route/CategoryPage/Container': {
        // 'member-property': {
        //     config,
        // },
        'member-function': {
            getSelectedSortFromUrl,
        },
    },
};

// noinspection JSValidateJSDoc

import {SecureSimpleProduct} from 'Query/ProductList.secure.type';
import {SecureIndexedProduct} from 'Util/Product/Product.secure.type';
import {calculateProductIncrements} from 'Util/ProductQuantities/increments';

/** @namespace Lebonvin/PwaSecure/Util/ProductQuantities/SoldIn/prepareSoldInMessage */
export function prepareSoldInMessage(
    product: SecureIndexedProduct | Partial<SecureSimpleProduct>,
    longMessages: boolean = false,
): string | undefined {
    const { step, minimum } = calculateProductIncrements(product);

    if (minimum < 0) {
        return undefined;
    }

    const singleBottle: string = longMessages
        ? 'Sold by the bottle.'
        : 'Single bottles.';
    const cases: string = longMessages
        ? `Sold in cases of ${step}.`
        : `Cases of ${ step }.`;
    const minimumOrder: string = longMessages
        ? ` Minimum order ${ minimum } bottles.`
        : ` Minimum order ${ minimum }.`;

    const showMinimum: boolean = (minimum > 1) && (minimum !== step);

    return step > 1
        ? `${cases}${ showMinimum ? minimumOrder : '' }`
        : `${singleBottle}${ showMinimum ? minimumOrder : '' }`;
}
